.category-links__title {
    font-weight: 600;
}

.category-links__item {
    @include mq($from: tablet) {
        &:hover {
            .category-links__text-container .category-links__title {
                color: $color-white;
            }
        }
    }
}

.cms-home {
    [data-content-type="banner"] {
        .pagebuilder-banner-text-wrapper {
            flex: 1 1 auto;
            max-width: 530px;
            min-width: 475px;
            height: unset;
            padding: 0 64px;
            @include mq($from: tablet) {
                padding: unset;
            }
        }

        .pagebuilder-banner-text-content {
            [data-element="title_element"] {
                @include mq($from: tablet) {
                    font-size: 32px;
                    font-weight: 600;
                    letter-spacing: 0.64px;
                    padding-left: 5%;
                }
            }

            [data-element="content"] {
                @include mq($from: tablet) {
                    padding-left: 5%;
                    margin-top: 64px;
                }

                h3 {
                    @include mq($from: tablet) {
                        font-size: 22px;
                        font-weight: 600;
                        letter-spacing: 0.44px;
                    }
                }

                p {
                    @include mq($from: tablet) {
                        font-size: 18px;
                        letter-spacing: 0px;
                    }
                }
            }

            .pagebuilder-banner-button {
                @include mq($from: tablet) {
                    margin-left: 5% !important;
                }
            }
        }
    }

    [data-content-type="pinpoint_pagebuilderuspbar"] {
        @include mq($from: tablet) {
            max-width: var(--constrained-width);
            margin: var(--constrained-margin);
        }

        [data-content-type="pinpoint_pagebuilderuspitem"]:not(:last-child) {
            .usp-sub-title-text {
                font-size: 14px;
            }
        }
    }
    [data-content-type="row"] {
        .category-grid {
            @include mq($from: tablet) {
                padding-top: 60px !important;
            }

            h3 {
                text-transform: uppercase;
                font-weight: normal !important;
            }
        }
    }

    .pb-homepage-enhanced-link .text-container [data-element=enhanced_link_title_element],
    .pb-homepage-enhanced-link .text-container [data-element=enhanced_link_subtext_element] {
        font-weight: normal !important;
        margin: 6px 0 !important;
    }

    .amblog-title {
        text-transform: uppercase !important;
    }
}

.homepage__nearest-branch {
    border-radius: $global-radius;
}

.usp-bar {
    @include mq($until: desktop) {
        padding-bottom: 32px !important;
    }
}

.products-related .view-product.secondary a {
    color: $color-white !important;
}

.logo {
    @include mq($until: desktop) {
        max-width: unset;
    }

    img {
        @include mq($until: desktop) {
            max-width: 60px;
            max-height: 36px;
        }
        @include mq($from: desktop) {
            max-width: 110px;
            max-height: 68px;
        }
    }

    
    @include mq($from: desktop) {
        display: flex;
        gap: 6px;
        max-width: 260px;
        
        img {
            width: 50%;
            flex: 1;
        }

        &::after {
            content: "";
            display: block;
            width: 50%;
            max-height: 50px;
            flex: 1;
            background-image: url("../images/secondary-logo.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left center;
        }
    }
}
$location-icon-pagebuilder: url("../images/icons/icon--store-locator.svg");
$enhanced-link-category-grid-header-colour: $color-black;
$enhanced-link-category-grid-text-container-hover-colour: $color-grey--light;
$enhanced-link-category-grid-text-container-hover-background-colour: $color-grey--light;
$enhanced-link-category-grid-sub-text-colour: $color-primary;
$enhanced-link-category-grid-chevron: $color-primary;
$enhanced-link-category-grid-header-colour--hover: $color-white;
$enhanced-link-category-grid-subtext-weight: 400;

$enhanced-link-subtext-element-icon-color: $color-white;

// URL resolves to `static/frontend/LawsonsAVS/avs-theme/en_GB/fonts/GillSans.ttf`
@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSans.ttf') format('ttf'),
  url('../fonts/GillSans.otf') format('opentype');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSansItalic.otf') format('opentype');
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSansMedium.otf') format('opentype');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSansMediumItalic.otf') format('opentype');
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSansBold.otf') format('opentype');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSansBoldItalic.otf') format('opentype');
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSansHeavy.otf') format('opentype');
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSansHeavyItalic.otf') format('opentype');
  font-display: swap;
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSansLight.otf') format('opentype');
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../fonts/GillSansLightItalic.otf') format('opentype');
  font-display: swap;
  font-weight: 200;
  font-style: italic;
}
